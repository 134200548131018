<template>
  <div style="position: relative; height: 600px; width: 100%;">
    <canvas id="ventasGraph"></canvas>
  </div>

</template>

<script>
import Chart from 'chart.js'


export default {
  data() {
    return {
      ordenes: [],
      ordenesxMes: {
        enero: [],
        febrero: [],
        marzo: [],
        abril: [],
        mayo: [],
        junio: [],
        julio: [],
        agosto: [],
        septiembre: [],
        octubre: [],
        noviembre: [],
        diciembre: []
      }
    }
  },
  methods: {
    ordenesPorMes(ordenes) {
      const currentYear = new Date().getFullYear()
      ordenes.forEach(orden => {
        let estatusOrden = orden.estatus_orden.dato
        let ordenParts = orden.numero_orden.toString().split("-")
        let ordenMonth = ordenParts[3]
        let ordenYear = ordenParts[2]
        if(ordenYear == currentYear &&
            (
              estatusOrden !== "Creada" &&
              estatusOrden !== "Cancelada" &&
              estatusOrden !== "Pago rechazado"
            )
        ){
          if(ordenMonth == 1) {
            this.ordenesxMes['enero'].push(orden)
          }
          if(ordenMonth == 2) {
            this.ordenesxMes['febrero'].push(orden)
          }
          if(ordenMonth == 3) {
            this.ordenesxMes['marzo'].push(orden)
          }
          if(ordenMonth == 4) {
            this.ordenesxMes['abril'].push(orden)
          }
          if(ordenMonth == 5) {
            this.ordenesxMes['mayo'].push(orden)
          }
          if(ordenMonth == 6) {
            this.ordenesxMes['junio'].push(orden)
          }
          if(ordenMonth == 7) {
            this.ordenesxMes['julio'].push(orden)
          }
          if(ordenMonth == 8) {
            this.ordenesxMes['agosto'].push(orden)
          }
          if(ordenMonth == 9) {
            this.ordenesxMes['septiembre'].push(orden)
          }
          if(ordenMonth == 10) {
            this.ordenesxMes['octubre'].push(orden)
          }
          if(ordenMonth == 11) {
            this.ordenesxMes['noviembre'].push(orden)
          }
          if(ordenMonth == 12) {
            this.ordenesxMes['diciembre'].push(orden)
          }
        }
      })
      this.createChart()
    },
    createChart() {
      const ctx = document.getElementById('ventasGraph')
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
          ],
          datasets: [{
            label: 'Ventas Por Mes',
            data: [
              this.ordenesxMes.enero.length,
              this.ordenesxMes.febrero.length,
              this.ordenesxMes.marzo.length,
              this.ordenesxMes.abril.length,
              this.ordenesxMes.mayo.length,
              this.ordenesxMes.junio.length,
              this.ordenesxMes.julio.length,
              this.ordenesxMes.agosto.length,
              this.ordenesxMes.septiembre.length,
              this.ordenesxMes.octubre.length,
              this.ordenesxMes.noviembre.length,
              this.ordenesxMes.diciembre.length, 
            ],
            backgroundColor: [
              'rgba(255,55,0,.7)'
            ],
            borderColor: [
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
              '#FF3700',
            ],
            borderWidth: 3
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                padding:25,
              }
            }]
          },
          title: {
            display: true,
            text: "VENTAS POR MES",
            fontSize: 28,
            fontColor: '#2D4761',
          }
        }
      })
    }
  }
}
</script>
